/// styling for privacy policies pages
.privacy-content {
  padding: 7.5vw;
  font-size: $medium;
  background-color: $dark-cyan;
  min-height: 100vh;
  box-sizing: border-box;
  ///link styling
  a {
    color: $cyan;

    text-decoration: underline;
  }

  p {
    opacity: 100%;
    font-size: $medium !important;
    line-height: calc(1.5*$medium);
  }

  ///titles
  h1 {
    font-size: $big-heading;
    margin-bottom: 80px;
  }

  h2 {
    font-size: $normal-heading;
    margin-top: 80px;
  }

  ///table of contents styling
  .table-of-contents {
    display: flex;
    flex-direction: column;

    a {
      margin: 0.3rem 0;
    }
  }

  li {
    margin-top: 10px;
  }
}

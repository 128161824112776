@import "./config.scss";
$animation-time: 14s;
//////////////////// HOME ///////////////////////////////////////
.home-container {
  display: flex;
  width: $shrink-size;
  height: 100vh;
  max-width: $max-w;
  margin: auto;
  text-align: left;
  overflow: hidden;

  .home-titles {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    margin: auto 0;
    height: fit-content;
    width: $max-w;

    h1 {
      width: fit-content;
      margin: 10px 0;
      letter-spacing: -1px;
      @include text-shade;
    }
    .button-wrapper {
      button {
        margin-top: 50px;
      }
      .home-button {
        margin-right: $normal-text;
      }
    }
  }

  .home-promo-wrapper {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .rocket-illustration-wrapper {
      display: flex;
      .rocket-cloud-wrapper {
        position: relative;
        flex: 1.5;
      }
      .clouds-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
      .cloud {
        z-index: 2;
        position: absolute;
        background: $white;
        color: $black;
        text-align: center;
        border-radius: 30px;
        width: 100px;
        height: 35px;
        font-size: medium;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        justify-content: center;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        animation: falling $animation-time linear infinite;
        &:nth-child(2) {
          left: -100px;
          animation-delay: calc(-1 * $animation-time/4);
        }
        &:nth-child(3) {
          left: -60px;
          animation-delay: calc(-3 * $animation-time/4);
        }
        &:nth-child(4) {
          right: -160px;
          animation-delay: calc(-2 * $animation-time/4);
        }
        &:nth-child(5) {
          right: -120px;
        }
      }
      .illustration-description {
        flex: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .cta-wrapper {
          width: fit-content;
          text-align: center;
        }
        .ezbro-ai-cloud {
          height: fit-content;
          margin: 0 auto;
          @include text-shade();
          font-size: $normal-heading;
          font-weight: bold;
        }
        .ezbro-button {
          width: fit-content;
          margin: 10px auto;
        }
      }
    }
  }

  @keyframes falling {
    0% {
      transform: translateY(-200px);
      opacity: 0;
    }
    50% {
      transform: translateY(0px);
      opacity: 0.9;
    }
    100% {
      transform: translateY(200px);
      opacity: 0;
    }
  }
}

/// global variables sizing
$big-heading: calc(23px + 2vw);
$normal-heading: 26px;
$small-heading: 22px;
$normal-text: 16px;
$small-text: 14px;
$medium: 18px;
$max-w: 1600px;
$shrink-size: 90%;
$normal-radius: 20px;
$small-radius: 10px;
$dimmed: 0.6;
$font: "Manrope";

/// colors
$super-black: #000;
$black: #020204;
$dark-blue: #06061b;
$candy: #655fbf;
$cyan: #00a6cf;
$dark-cyan: #010e14;
$gold: #b6b64a;
$red: #f24439;
$white: #fff;
$green: #2d7231;

//config for the main section (full width/height)
@mixin main-section {
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  // margin: auto;
}

//for any div that centers their children
@mixin center-parents {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

@mixin text-shade {
  text-shadow: 0 1px rgba(0, 0, 0, 0.07);
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(
    160deg,
    #fff 65%,
    rgba(255, 255, 255, 0.43)
  );
  background-clip: text;
  -webkit-background-clip: text;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: $font, sans-serif !important;
  font-size: $normal-text;
  color: $white;
}

body {
  background-image: linear-gradient(to bottom, $super-black, $black);
}

.starry-bg {
  position: absolute;
  inset: 0;
}

/// Text settings ///////////////////////////////////////
p {
  font-weight: 400;
  letter-spacing: -0.2px;
}

//setting for links to omit underline and purple visited.
a {
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
  transition: all 300ms cubic-bezier(0.165, 0.84, 0.44, 1);

  &:hover {
    color: $cyan;
  }

  &:visited {
    color: inherit;
  }
}

h1 {
  font-size: $big-heading;
  font-weight: 700;
}

h2 {
  font-weight: 600;
  font-size: $small-heading;
}

h3 {
  font-size: 20px;
  font-weight: 500;
}

///// BUTTON STYLING ///////////////////////////////
.explore-button {
  cursor: pointer;
  font-family: $font, sans-serif;
  font-size: $normal-text;
  font-weight: 600;
  display: inline-block;
  padding: 13px 22px;
  color: $white;
  border-radius: $normal-radius;
  backdrop-filter: blur(10px);
  border: none;
  background: linear-gradient(to right, $candy, $cyan);
  transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);

  &:hover {
    box-shadow: 0 0.6em 0.5em -0.4em $candy;
    transform: translateY(-0.25em);
  }
}

.secondary-button {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.1)
  );
  &:hover {
    box-shadow: 0 0.7em 0.5em -0.4em rgba(255, 255, 255, 0.15);
    transform: translateY(-0.25em);
  }
}

///// THE RUNNING TEXT APPEARING BEFORE EACH SECTIONS' CONTENTS ////////////////////
.section-intro {
  flex-direction: column;
  position: absolute;
  text-align: center;
  z-index: 4000;
  margin: auto;
  pointer-events: none;
}

.section {
  @include main-section();
  display: flex;
  color: $white;
}

///a section divided in half, with one in white & one in lt blue
.section-left {
  @include center-parents();
  flex: 1;
  text-align: left;
  overflow-y: hidden;
  overflow-x: hidden;
  margin: 0;
}

.section-left {
  flex-grow: 1;
  flex-direction: column;
}

/// media query for mobile /////////////
@media (max-width: 1100px) {
  #Home {
    .home-container {
      flex-direction: column;

      .section-left {
        flex-grow: 0.9;

        .home-titles {
          width: 100%;

          h1 {
            margin: auto;
          }

          .button-wrapper {
            text-align: center;
            display: flex;
            width: fit-content;
            margin: 25px auto;
            flex-direction: column;
            button {
              margin-top: 10px;
            }
          }
        }
      }

      .home-promo-wrapper {
        .home-promo {
          max-width: 600px;
          height: 100%;
        }

        img.essay {
          max-width: 600px;
        }
      }
    }
  }

  #About {
    .section-left {
      .about-content {
        .about-titles {
          width: $shrink-size;

          .title-parent {
            h2 {
              font-size: $medium;
            }
          }

          .title-child {
            p {
              font-size: $normal-text !important;
            }
          }
        }

        .ai-icon {
          width: 200px !important;
        }
      }
    }
  }

  //shrinking the table text sizing
  .product {
    .service-information {
      .service-information-table {
        th,
        td {
          font-size: $normal-text !important;
        }
      }
    }
  }

  #Essay {
    .product-basic {
      padding-top: 10vh;
    }
  }

  #Footer {
    .footer-list {
      flex-direction: column;

      .ant-collapse-header {
        font-size: $normal-text;
      }

      .ant-collapse-content-box {
        font-size: $normal-text;
      }
    }

    .secondary-footer {
      flex-direction: column-reverse;

      p.copyright {
        font-size: $small-text !important;
      }
    }
  }
}

/////////////////////////// CONTACTS ////////////////////////////
#Contacts {
    .section-left {
        background-color: $black;
        position: relative;

        //let's talk section
        .intro-contact {
            left: 0;
            right: 0;
            text-align: center;
        }

        .titles {
            width: 80%;

            ///contact CTA
            h1 {
                color: $white;
                margin-bottom: 6vh;
            }

            ///description headings
            h5 {
                font-size: $normal-text;
                color: $cyan;
                margin: 0;
                margin-bottom: 0.4vh;
            }

            p {
                margin: 0.5vh 0;
                color: $white;
            }

            .info-row {
                display: flex;
                vertical-align: center;
                margin-top: 3vh;
                margin-bottom: 1vh;

                .icon {
                    @include center-parents();
                    margin-right: 1rem;

                    svg {
                        scale: 1.8;
                        color: $black;
                    }
                }
            }
        }
    }

    .section-right {
        background-color: $black;

        //intro text pop up when scrolling
        .intro-contact {
            left: 0;
            right: 0;
            text-align: center;

        }

        .titles {
            @include center-parents();
            width: 80%;
            height: 80%;

            ///sending email form
            form {
                display: flex;
                flex-direction: column;
                background-color: $black;
                border-radius: $normal-radius;
                width: 100%;
                max-height: 100%;
                padding: 50px 30px;

                .field-splitter {
                    display: grid;
                    grid-template-columns: 50% 50%;

                    input.name {
                        width: 90%;
                    }
                }

                label {
                    font-size: $normal-text;
                    font-weight: 600;
                    color: $cyan;
                }

                input,
                textarea {
                    border: none;
                    outline: none;
                    box-sizing: border-box;
                    font-family: 'Archivo', sans-serif;
                    border-radius: $small-radius;
                    margin-bottom: 4vh;
                    margin-top: 0.6vh;
                    padding: 0.8rem;
                    font-size: $normal-text;
                    background: $white;
                    transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);


                    &::placeholder {
                        color: $cyan;
                    }

                    &:focus {
                        outline: 1px solid $black;
                    }
                }

                textarea {
                    height: 8rem;
                    resize: none;
                }

                .explore-button {
                    position: static;
                    width: 8rem;
                    right: 0;
                    align-self: flex-end;
                }
            }
        }
    }


}
$rocket-width: 120px;
$fin-size: 70px;
$red: #a75248;
$lightgrey: #dadada;
$midgrey: #c7c5c5;
$darkgrey: #554842;
$darkcandy: #27244b;
$bolt-offset: 4.1px;
.rocket-wrapper {
  position: relative;
  width: $rocket-width;
  left: 0;
  right: 0;
  margin: 0 auto;
  .rocket-body {
    position: relative;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: $rocket-width;
    animation: shaky 400ms infinite;
    z-index: 1;
    .body {
      background: linear-gradient(90deg, $white 60%, #c5c5c5 85%, #807e7e 100%);
      height: calc(2.2 * $rocket-width);
      border-top-right-radius: 100%;
      border-top-left-radius: 100%;
      border-bottom-left-radius: 40px;
      border-bottom-right-radius: 40px;
    }
    &:before {
      //the exausting fuse
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: calc($rocket-width/2);
      height: 13px;
      background: linear-gradient(to bottom, $darkcandy 10%, $candy);
      bottom: -13px;
      border-bottom-right-radius: 50%;
      border-bottom-left-radius: 50%;
    }
  }
  .window-border {
    top: 60px;
    left: 0;
    right: 0;
    margin: 0 auto;
    position: absolute;
    width: calc($rocket-width/2 + 20px);
    aspect-ratio: 1/1;
    border-radius: 100%;
    background: linear-gradient(90deg, $lightgrey 65%, #a09c9c 100%);
    .window {
      position: absolute;
      width: calc($rocket-width/2);
      aspect-ratio: 1/1;
      border-radius: 100%;
      background: linear-gradient(90deg, $candy 10%, $cyan);
      left: 0;
      right: 0;
      margin: auto;
      top: 0px;
      bottom: 0;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        width: calc($rocket-width / 2);
        height: calc($rocket-width / 2);
        box-shadow: inset rgba(black, 0.075) -2vmin -2vmin 0 0,
          inset rgba(black, 0.1) -1vmin -1.5vmin 0 0;
        border-radius: 100%;
      }
    }
    .bolt {
      width: 4px;
      height: 4px;
      background-color: $darkcandy;
      position: absolute;
      border-radius: 50%;
      opacity: 40%;
    }
    .bolt-top {
      top: $bolt-offset;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
    .bolt-left {
      bottom: 0;
      left: $bolt-offset;
      top: 0;
      margin: auto 0;
    }
    .bolt-right {
      bottom: 0;
      right: $bolt-offset;
      top: 0;
      margin: auto 0;
    }
    .bolt-bot {
      bottom: $bolt-offset;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
  .rocket-label {
    position: absolute;
    top: 162px;
    left: 3px;
    writing-mode: vertical-rl;
    text-orientation: upright;
    transform: perspective(100px) rotateY(-15deg);
    color: $black;
    font-weight: 800;
    font-size: 19px;
    letter-spacing: -9px;
    .rocket-label-text {
      padding-bottom: 5px;
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(90deg, $candy 10%, $cyan);
      background-clip: text;
      -webkit-background-clip: text;
    }
  }
  .fin {
    position: absolute;
    z-index: -100;
    height: $fin-size;
    aspect-ratio: 1/1;
  }
  .fin-left {
    left: calc($fin-size/-2);
    top: calc(100% - $fin-size);
    border-top-left-radius: 70%;
    border-bottom-left-radius: 15%;
    background: linear-gradient(110deg, $candy 40%, $darkcandy);
  }
  .fin-right {
    right: calc($fin-size/-2);
    top: calc(100% - $fin-size);
    border-top-right-radius: 70%;
    border-bottom-right-radius: 15%;
    background: linear-gradient(90deg, $darkcandy 40%, $candy);
  }
  .exhaust-flame {
    position: absolute;
    top: 100%;
    width: calc($rocket-width/2.5);
    background: linear-gradient(to bottom, $gold, transparent 70%);
    height: 150px;
    left: 0;
    right: 0;
    margin: 0 auto;
    animation: exhaust 0.1s alternate infinite;
  }

  @keyframes exhaust {
    to {
      transform: scaleX(0.98) translateY(-1vmin);
    }
  }

  @keyframes shaky {
    0% {
      transform: translate3d(0px, 0px, 0);
    }
    50% {
      transform: translate3d(0px, -2px, 0);
    }
    100% {
      transform: translate3d(0px, 0px, 0);
    }
  }
}

//////////////////////// ABOUT ////////////////////////////////////
#About {
    .section-left {
        flex-direction: row;

        .intro-one-about {
            @include text-shade;
        }

        .about-content {
            @include center-parents();
            position: relative;
            height: 100%;
            width: 100%;

            .ai-icon-wrapper {
                transform: perspective(350px) rotateX(25deg) translateY(-50px);

                .ai-icon {
                    width: 280px;
                }
            }


            .about-titles {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: justify;
                text-align-last: center;
                width: 80%;
                max-width: 800px;

                .title-parent {
                    @include text-shade;

                    h2 {
                        margin-top: 0;
                    }
                }

                .title-child {
                    opacity: 0.6;
                    font-weight: 300;

                    p {
                        font-size: $medium !important;
                    }

                }


                .about-button {
                    margin-top: 10px;
                }
            }
        }
    }
}
@import "./config.scss";

// Navigation bar ///////////////////////////////////////////
.nav {
    @include center-parents();
    position: fixed;
    height: 8%;
    width: 100vw;
    z-index: 2001;
    background: transparent;
    backdrop-filter: blur(50px);

    .nav-wrapper {
        @include center-parents();
        justify-content: space-between;
        height: 100%;
        width: $shrink-size;
        max-width: $max-w;
        right: 10px;

        img {
            height: 90%;
        }

        nav>a {
            padding: 0.3rem 0.5rem;
            color: $white;
            font-weight: 400;
            opacity: 0.3;
            transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }

        a.active {
            opacity: 1;
        }
    }

    //the wrapper of menu buttons
    .nav-select {
        @include center-parents();

        //naving button menu
        button {
            border: none;
            background-color: transparent;
            padding: 0;
            outline: none;
            top: 0;
        }
    }

    ///hamburger menu

    .stick-figure {
        width: 2rem;
        position: relative;
        z-index: 3000;
        height: 1.5rem;
        margin: auto;
        cursor: pointer;

        ///transition to an X
        .stick {
            position: absolute;
            margin: auto;
            top: 0;
            bottom: 0;
            width: 2rem;
            background-color: $white;
            height: 0.15rem;
            transition: all 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
        }

        .top {
            transform: translateY(0.4rem);
        }

        .bottom {
            transform: translateY(-0.4rem);
        }

        .open.top {
            transform: rotate(45deg);
        }

        .open.bottom {
            transform: rotate(-45deg);
        }

        .open.mid {
            transform: translateX(-2rem) scale(0, 0);
        }
    }

    ///menu appeared when clicking the hamburger
    .menu {
        @include center-parents();
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        background: $dark-blue;
        transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);

        nav {
            display: flex;
            flex-direction: column;

            a {
                font-weight: 400;
                color: $white;
                font-size: $big-heading;
                margin: 0.3rem 0;
            }
        }
    }
}
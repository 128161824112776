@import "./config.scss";

#Footer {
  @include center-parents();
  flex-direction: column;
  width: $shrink-size;
  max-width: $max-w;
  margin: 0 auto;
  padding: 20px 0;

  // for clickable links
  a {
    color: $white;
    font-weight: 400;

    &:hover {
      color: $cyan;
    }
  }

  //for normal text display
  p {
    margin: 0;
  }

  //disabled link
  a.dimmed {
    opacity: 0.2;
    cursor: default;

    &:hover {
      color: $white;
    }
  }

  .footer-list {
    gap: 20px;
    border: none;
    border-bottom: rgba(255, 255, 255, 0.3) 1px solid;
    border-radius: 0;
    padding-bottom: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .footer-comp {
    border: none;
    flex: 1;

    .ant-collapse-header {
      color: $white;
      font-family: $font, "sans-serif";
      padding-left: 0;
    }

    .ant-collapse-content {
      border: none;
      background: transparent;
      padding: auto 0;
      color: $white;
    }

    .ant-collapse-content-box {
      padding-top: 0;
    }

    h4 {
      text-transform: uppercase !important;
      color: $white;
      opacity: 60%;
      margin-bottom: 0.5rem;
      font-weight: 500;
    }

    ul {
      padding: 0;
      margin-bottom: 0.5rem;

      li {
        list-style: none;
        margin: 0.5rem 0;
      }
    }
  }

  .secondary-footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 20px;
    gap: 20px;

    .copyright {
      height: fit-content;
      margin-top: auto;
      margin-bottom: auto;
    }

    .verified-element {
      width: 8rem;
      img {
        width: 100%;
      }
    }

    .verified-element.dmca {
      display: flex;
      align-items: center;
      
      img {
        background-color: $white; 
        border-radius: $small-radius;
        padding: 5px;
        margin: auto;
      }
    }
  }
}

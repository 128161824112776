.product {
  @include center-parents();
  flex-direction: column;

  .product-basic {
    //whole product div
    @include center-parents();
    margin: 0 auto;
    min-height: 100vh;
    text-align: center;
    width: $shrink-size;
    max-width: $max-w;
    flex-direction: column;

    .product-title {
      //product title
      margin: 0;
      @include text-shade;
    }

    .product-des {
      //product description
      opacity: 0.5;
      font-size: $medium;
      margin-bottom: 30px;
    }

    .product-feat {
      //keeping the product features
      width: 100%;
      justify-content: center;
      margin-top: 8vh;

      .ant-row {
        width: 100%;
      }
    }
  }

  .service-information {
    width: $shrink-size;
    height: 100%;
    padding: 15vh 0;
    max-width: $max-w;

    .service-information-table {
      margin: 5vh auto;
      align-items: center;
      border-collapse: collapse;
      border-style: hidden;

      th,
      td {
        border: 1px solid rgba(255, 255, 255, 0.05);
        padding: 16px 16px;

      }
      td {
        font-size: $normal-text;
      }
    }

    .service-information-note {
      font-size: $small-text !important;
      font-style: italic;
      text-align: right;
      margin: 0;
      opacity: 0.5;
    }

    .ant-collapse {
      border: none;
      height: fit-content;
      padding: 0;
      border-radius: $small-radius;
      font-size: $normal-text;
      margin-bottom: 20px;
    }
    .ant-collapse-item {
      border-color: rgba(255, 255, 255, 0.1);
    }
    .ant-collapse-header {
      color: $white;
      .panel-header-config {
        display: flex;
        justify-content: space-between;
      }
    }
    .ant-collapse-content {
      background: transparent;
      color: $white;
      border: none;
      .ant-collapse-content-box {
        padding: 0 inherit;
      }
    }
  }
}

//tag stylings
.tag {
  font-size: $small-text;
  text-align: center;
  padding: 2px 4px;
  border-radius: $small-radius;
  height: fit-content;
  min-width: 68px;
}

.tag.ready {
  background-color: $green;
}

.tag.soon {
  background-color: $candy;
}

.glass-panel {
  font-family: $font, sans-serif;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px 20px 20px 20px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: $normal-radius;
  text-align: left;
  overflow: hidden;
  margin: auto;

  .glass-note {
    opacity: $dimmed;
    width: 90%;
  }

  .background {
    position: absolute;
    top: 25px;
    right: 35px;
    transform: scale(10);
    opacity: 0.1;
    filter: grayscale(100%);
    cursor: default;
  }

  &:hover {
    .background {
      filter: none;
      opacity: 0.7;
      transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
    }
  }
}
